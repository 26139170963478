
// Fonts
@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,600');
@import url('https://fonts.googleapis.com/css?family=Amatic+SC:400,700');

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

html, body
{
    margin: 0;
    padding: 0;
}

#content-wrapper
{
    margin-top: 90px;
}

// Overide
.form-horizontal .form-group
{
    margin-left: 0;
    margin-right: 0;
}

// Common
.link
{
    cursor: pointer;
}

.system-message
{
    padding: 15px;
    font-size: 1.5em;
    font-weight: bold;
    text-align: center;
}

.pad
{
    padding: 15px;
}

.no-pad
{
    padding: 0;
}

// DataTables
.paginate_button
{
    background: #3097D1;
    border: 1px solid #2a88bd;
    padding: 10px 15px;
    color: white;
    border-radius: 6px;
    margin: 0 3px;
}

.paginate_button.disabled
{
    background: #ededed;
    border: grey;
    color: gray;
}

.paginate_button:hover
{
    background: #ededed;
    color: #333;
    text-decoration: none;
}

.navbar
{
    height: 90px;
    margin-bottom: 0px;
}

.navbar-inverse
{
    background: rgba(0,0,0,.8);
}

.navbar-nav > li > a
{
    padding: 35px 15px;
}

@media screen and (max-width: 768px)
{

    #app-navbar-collapse
    {
        background: #121212;
        margin-top: 39px;
    }

    .navbar-nav > li > a
    {
        padding: 10px 20px;
    }

    .navbar-toggle
    {
        top: 20px;
    }

}
